<template>
	<div class="container-fluid">
		<div class="row py-3 justify-content-center">
			<div class="col-12">
				<div class="text-right">
					<b-button
						variant="primary"
						@click="$refs.modalFindPlayers.show()"
						v-if="
							userType === 'ADMI' || userType === 'FULL' || userType === 'SUPER'
						"
					>
						<i class="fa fa-plus"></i>
						<span class="ml-3 text-uppercase">{{ $t('add') }}</span>
					</b-button>
				</div>
				<ul class="list-group mb-4 mt-4">
					<li
						v-for="player in banneds"
						:key="player.id"
						class="list-group-item"
					>
						<div class="moves-item">
							<router-link
								:to="{
									name: 'PlayerHome',
									params: {
										lang: lang,
										console: console,
										playerSlug: player.user.slug
									}
								}"
							>
								<img
									:src="player.user.avatar"
									:alt="player.user.nick"
									class="moves-item__avatar  mr-2"
								/>
							</router-link>
							<div class="moves-item__nick-reason">
								<router-link
									:to="{
										name: 'PlayerHome',
										params: {
											lang: lang,
											console: console,
											playerSlug: player.user.slug
										}
									}"
								>
									<span :class="{ 'text-muted': !player.status }">{{
										player.user.nick
									}}</span>
								</router-link>
								<span
									:class="{ 'text-muted': !player.status }"
									class="d-block mt-3"
									>{{ player.reason }}</span
								>
							</div>
							<div class="moves-item__details">
								<div class="text-muted">
									<span>{{ $t('bannedStart') }}: </span>
									<span>{{ player.date_start }}</span>
								</div>
								<div>
									<span>{{ $t('bannedEnd') }}: </span>
									<span>{{ player.date_end }}</span>
								</div>
							</div>
							<div
								class="ml-3"
								v-if="
									(userType === 'ADMI' ||
										userType === 'FULL' ||
										userType === 'SUPER') &&
										player.status
								"
							>
								<b-button variant="danger" @click="openModalDelete(player)">
									<i class="fa fa-trash"></i>
								</b-button>
							</div>
						</div>
					</li>
				</ul>
				<b-pagination
					align="center"
					v-model="currentPage"
					:total-rows="rows"
					:per-page="perPage"
				></b-pagination>
			</div>
		</div>

		<b-form
			v-if="userType === 'ADMI' || userType === 'FULL' || userType === 'SUPER'"
			@submit.prevent="banned()"
		>
			<b-modal ref="modalFindPlayers" :title="$t('banned')">
				<div class="form-group">
					<label for="user">{{ $t('enterPlayerNick') }}</label>
					<multiselect
						v-model="playerSelected"
						id="user"
						label="nick"
						track-by="nick"
						open-direction="bottom"
						:options="players"
						:multiple="false"
						:searchable="true"
						:loading="loading"
						:internal-search="false"
						:clear-on-select="false"
						:close-on-select="true"
						:options-limit="10"
						:limit="10"
						:limit-text="limitText"
						:max-height="600"
						:show-no-results="false"
						:hide-selected="true"
						@search-change="asyncFind"
						:placeholder="$t('selectOption')"
						deselectLabel
						selectLabel
						selectedLabel
						name="userSelected"
						v-validate="'required'"
						:data-vv-as="$t('user')"
						:state="validateState('playerSelected')"
					></multiselect>
				</div>
				<div class="form-group">
					<label for="reason">{{ $t('reason') }}</label>
					<b-form-input v-model="form.reason" required />
				</div>
				<div class="form-group">
					<label for="date">{{ $t('date') }}</label>
					<b-form-input v-model="form.date" required type="date" />
				</div>
				<template slot="modal-footer">
					<b-button type="button" @click="$refs.modalFindPlayers.hide()">{{
						$t('cancel')
					}}</b-button>
					<b-button type="submit" variant="primary">BANEAR</b-button>
				</template>
			</b-modal>
		</b-form>
		<b-modal
			ref="modalDelete"
			:title="$t('confirmTitleDelete')"
			headerBgVariant="danger"
			headerTextVariant="light"
		>
			<p>{{ $t('confirmTextDelete') }}</p>
			<strong v-if="playerToDelete">{{ playerToDelete.user.nick }}</strong>
			<div slot="modal-footer">
				<b-button @click="$refs.modalDelete.hide()">{{
					$t('cancel')
				}}</b-button>
				<b-button variant="danger" @click="deletePlayer" class="ml-3">{{
					$t('delete')
				}}</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		data: () => ({
			players: [],
			playerSelected: false,
			playerToDelete: false,
			form: {},
			banneds: [],
			userType: '',
			currentPage: 1,
			rows: 0,
			perPage: 20
		}),
		watch: {
			currentPage(val) {
				this.fetchData(val)
			}
		},
		computed: {
			...mapGetters([
				'loading',
				'lang',
				'console',
				'team',
				'user',
				'tournamentFront'
			])
		},
		mounted() {
			this.$store.dispatch('SET_MENU_PRIMARY_TOURNAMENT', 'banned')
			this.fetchData()
		},
		methods: {
			deletePlayer() {
				const slug = this.$route.params.tournamentSlug
				const id = this.playerToDelete.id
				const path = `auth/${this.lang}/${this.console}/tournaments/${slug}/banned/${id}/delete`
				this.$axios.delete(path).then(() => {
					this.banneds = this.banneds.filter((p) => p.id !== id)
					this.$toastr.success(this.$t('updatedInfo'))
					this.$refs.modalDelete.hide()
				})
			},
			openModalDelete(player) {
				this.playerToDelete = player
				this.$refs.modalDelete.show()
			},
			fetchData(page) {
				const slug = this.$route.params.tournamentSlug
				const path = `${this.lang}/${this.console}/tournaments/${slug}/banned`
				this.$axios
					.get(path, { params: { page: page || 1 } })
					.then((response) => {
						this.banneds = response.data.banned.data
						this.userType = response.data.type
						this.rows = response.data.banned.total
						this.perPage = response.data.banned.per_page
					})
			},
			banned() {
				if (this.playerSelected.id) {
					this.form.user_id = this.playerSelected.id
					this.form.tournament_id = this.tournamentFront.tournament.id
					const slug = this.$route.params.tournamentSlug
					const path = `auth/${this.lang}/${this.console}/tournaments/${slug}/banned/store`
					this.$axios.post(path, this.form).then((response) => {
						this.banneds.unshift(response.data.data)
						this.$toastr.success(this.$t('updatedInfo'))
						this.$refs.modalFindPlayers.show()
					})
				}
			},
			limitText(count) {
				return `and ${count} other players`
			},
			asyncFind(query) {
				if (query && query.length > 2) {
					const tournamentId = this.tournamentFront.tournament.id
					const path = `auth/${this.lang}/${this.console}/tournaments/${tournamentId}/banned/find-players`
					this.$axios
						.get(path, { params: { nick: query } })
						.then((response) => {
							this.players = response.data.data
						})
				}
			}
		}
	}
</script>

<style lang="scss">
	.moves-item__details {
		margin-left: auto;
		margin-right: 0 !important;
		text-align: right;
		min-width: 150px;
		span {
			line-height: 2;
		}
	}
	@media (max-width: 500px) {
		.list-group-item {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
		.moves-item {
			.moves-item__details {
				div {
					span {
						&:first-of-type {
							display: none;
						}
					}
				}
			}
		}
	}
</style>
